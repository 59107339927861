// In a new file, e.g., versionCheck.ts
import { useNavigation, useRevalidator, useMatches } from '@remix-run/react'
import { useState, useEffect } from 'react'

let latestVersion: string | null = null

export function trackVersion(version: string | null) {
	if (version && version !== latestVersion) {
		latestVersion = version
	}
}

export function useVersionCheck(initialVersion: string) {
	const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false)
	const navigation = useNavigation()
	const revalidator = useRevalidator()
	const matches = useMatches()

	useEffect(() => {
		if (latestVersion && latestVersion !== initialVersion) {
			setIsNewVersionAvailable(true)
		}
	}, [navigation.state, revalidator.state, matches, initialVersion])

	return { isNewVersionAvailable, setIsNewVersionAvailable }
}
